define("discourse/plugins/dis-6do-tts/discourse/models/voice", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Voice = _object.default.extend({});
  Voice.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/tts", {
        method: "get"
      }).then(result => {
        const files = result.files;
        const models = result.models;
        return {
          files: files.length ? files : [],
          models: models.length ? models : [],
          tier: result.tier,
          usage: result.usage
        };
      });
    }
  });
  var _default = _exports.default = Voice;
});