define("discourse/plugins/dis-6do-tts/discourse/controllers/admin-plugins-dis-6do-tts", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/dis-6do-tts/discourse/components/update-tier-modal", "discourse/plugins/dis-6do-tts/discourse/components/create-tier-modal"], function (_exports, _controller, _object, _service, _updateTierModal, _createTierModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.service)(),
    init() {
      this._super(...arguments);
      this.set('availableTiers', [{
        value: 'tier_1',
        label: 'Tier 1'
      }, {
        value: 'tier_2',
        label: 'Tier 2'
      }, {
        value: 'tier_3',
        label: 'Tier 3'
      }]);
      this.set('selectedTier', 'tier_1');
    },
    openUpdateTierModal() {
      return this.modal.show(_updateTierModal.default);
    },
    openCreateTierModal() {
      return this.modal.show(_createTierModal.default);
    }
  }, [["method", "openUpdateTierModal", [_object.action]], ["method", "openCreateTierModal", [_object.action]]]));
});