define("discourse/plugins/dis-6do-tts/discourse/initializers/setup-tts", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "setup-tts",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.11", api => {
        api.addNavigationBarItem({
          name: "tts",
          displayName: _I18n.default.t("dis_6do_tts.admin_navigation"),
          href: "/tts"
        });
      });
    }
  };
});