define("discourse/plugins/dis-6do-tts/discourse/controllers/admin-plugins-dis-6do-tts-tiers", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/dis-6do-tts/discourse/components/update-tier-modal", "discourse/plugins/dis-6do-tts/discourse/components/create-tier-modal", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse-i18n"], function (_exports, _controller, _object, _service, _updateTierModal, _createTierModal, _decorators, _ajax, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.service)(),
    dialog: (0, _service.service)(),
    init() {
      this._super(...arguments);
      this.set('availableTiers', []);
      this.set('tierList', {});
      this.getTiers();
    },
    throwAlert(detail) {
      return this.dialog.alert(detail);
    },
    async getTiers() {
      try {
        let _tierList = {};
        const tiers = await (0, _ajax.ajax)("/tts/admin/tiers");
        tiers.forEach((tier, i) => {
          _tierList[`tier_${i + 1}`] = {
            id: tier.id,
            name: tier.group_name,
            priorityModels: JSON.parse(tier.priority_models).map(raw => `${raw.locale} | ${raw.gender} | ${raw.name}`),
            availableModels: JSON.parse(tier.models).map(raw => `${raw.locale} | ${raw.gender} | ${raw.name}`),
            processingMinsPerMonth: tier.number_of_processed_mins,
            processedFilesPerMonth: tier.number_of_processed_files,
            costPerMinute: tier.cost_per_additional_mins,
            costPerAdditionalFile: tier.cost_per_additional_processed_files
          };
          const cacheAvailableTiers = [...this.availableTiers];
          cacheAvailableTiers.push({
            value: `tier_${i + 1}`,
            label: tier.group_name
          });
          this.set('availableTiers', cacheAvailableTiers);
        });
        this.set('tierList', _tierList);
      } catch (err) {
        console.log(err);
      }
    },
    model(tier) {
      return {
        ...this.tierList[tier],
        availableModels: this.tierList[tier].availableModels.join(", "),
        priorityModels: this.tierList[tier].priorityModels.join(", "),
        processingMinsPerMonth: this.tierList[tier].processingMinsPerMonth
      };
    },
    openUpdateTierModal(tierId) {
      return this.modal.show(_updateTierModal.default, {
        model: {
          tierId: tierId
        }
      });
    },
    openCreateTierModal() {
      return this.modal.show(_createTierModal.default);
    },
    deleteTier(tierId) {
      this.dialog.yesNoConfirm({
        message: _discourseI18n.default.t("dis_6do_tts.admin.tiers.delete_tier_modal.title"),
        didConfirm: async () => {
          try {
            await (0, _ajax.ajax)(`/tts/admin/tier/${tierId}`, {
              method: "delete"
            });
            window.location.reload();
          } catch (err) {
            console.log(err);
            this.throwAlert(err.jqXHR.responseJSON.errors[0]);
          }
          this.send("closeModal");
        }
      });
    }
  }, [["method", "model", [(0, _decorators.default)("selectedTier")]], ["method", "openUpdateTierModal", [_object.action]], ["method", "openCreateTierModal", [_object.action]], ["method", "deleteTier", [_object.action]]]));
});