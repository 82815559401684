define("discourse/plugins/dis-6do-tts/discourse/templates/admin/plugins-dis-6do-tts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h2>Discourse 6DO TTS</h2>
  
  <ul class="nav nav-pills">
      <NavItem
          @route="adminPlugins.dis-6do-tts.tiers"
          @label="dis_6do_tts.admin.nav.tiers"
      />
      <NavItem
          @route="adminPlugins.dis-6do-tts.other"
          @label="dis_6do_tts.admin.nav.other"
      />
  </ul>
  
  <hr />
  
  <div id="dis-6do-tts-admin">
      {{outlet}}
  </div>
  */
  {
    "id": "dRPdskWK",
    "block": "[[[10,\"h2\"],[12],[1,\"Discourse 6DO TTS\"],[13],[1,\"\\n\\n\"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.dis-6do-tts.tiers\",\"dis_6do_tts.admin.nav.tiers\"]],null],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.dis-6do-tts.other\",\"dis_6do_tts.admin.nav.other\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"hr\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,1,\"dis-6do-tts-admin\"],[12],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/dis-6do-tts/discourse/templates/admin/plugins-dis-6do-tts.hbs",
    "isStrictMode": false
  });
});