define("discourse/plugins/dis-6do-tts/discourse/routes/tts-index", ["exports", "@ember/routing/route", "@ember/service", "discourse/plugins/dis-6do-tts/discourse/models/voice"], function (_exports, _route, _service, _voice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    router: (0, _service.inject)(),
    model() {
      return _voice.default.findAll();
    }
  });
});